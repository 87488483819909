import React from 'react';
import { Drawer, Typography } from 'antd';

const { Text } = Typography;

const SingleOffer = props => {
  const { drawer, setDrawer, selectedOffer, setSelectedOffer } = props;

  const transformedOffer = {
    Number: selectedOffer.offer_number,
    ID: selectedOffer.id,
    'Issued at': selectedOffer.issued_at,
    Status: selectedOffer.status,
    'User ID': selectedOffer.user_id,
    'User email': selectedOffer.billing_email,
    'User phone': selectedOffer.billing_phone || '-',
    'Billing company': selectedOffer.billing_company || '-',
    'Billing full name': selectedOffer.billing_first_name || '-',
    // 'Billing last name': selectedOffer.billing_last_name || '-',
    'Billing address': selectedOffer.billing_address || '-',
    'Billing city': selectedOffer.billing_city || '-',
    'Billing ZIP code': selectedOffer.billing_zip_code || '-',
    'Billing country': selectedOffer.billing_country || '-',
  };

  return (
    <Drawer
      width={640}
      placement="right"
      closable={false}
      onClose={() => {
        setDrawer(false);
        setTimeout(() => {
          setSelectedOffer({});
        }, 300);
      }}
      visible={drawer}
    >
      <div>
        {Object.keys(transformedOffer).map((keyName, i) => {
          return (
            <p key={i}>
              <Text>
                {keyName}:{' '}
                <Text type="secondary">{transformedOffer[keyName]}</Text>
              </Text>
            </p>
          );
        })}
      </div>
    </Drawer>
  );
};

export default SingleOffer;
