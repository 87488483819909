import React, { useState, useEffect, createContext, useContext } from "react";
import { AwsClient } from "aws4fetch";

import { AuthContext } from "./AuthContext";
import { API_ENDPOINT } from "../consts/globals";

export const VenuesContext = createContext();

const VenuesContextProvider = (props) => {
  const { creds } = useContext(AuthContext);
  const [venues, setVenues] = useState([]);
  const [response, setResponse] = useState({});

  const abortController = new AbortController();
  const signal = abortController.signal;

  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken,
  });

  const getVenues = async () => {
    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/venues`, {
        signal: signal,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setVenues(responseJson.data);
    } catch (error) {
      console.log(error);
    }
  };

  const createVenue = async (venue) => {
    setResponse({});

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/venues`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(venue),
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getVenues();
    } catch (error) {
      console.log(error);
    }
  };

  const editVenue = async (venue, id) => {
    setResponse({});

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/venues/${id}`, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(venue),
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getVenues();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteVenue = async (id) => {
    setResponse({});

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/venues/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getVenues();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVenues();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VenuesContext.Provider
      value={{
        venues,
        getVenues,
        createVenue,
        editVenue,
        deleteVenue,
        response,
        setResponse,
      }}
    >
      {props.children}
    </VenuesContext.Provider>
  );
};

export default VenuesContextProvider;
