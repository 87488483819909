import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Drawer, Typography, Spin, Row, Col } from 'antd';

import { NewsContext } from '../../contexts/NewsContext';

const { Text } = Typography;

const StyledImageWrapper = styled.div`
  margin-bottom: 2.5rem;
`;

const StyledImage = styled.img`
  max-width: 100%;
  object-fit: cover;
`;

const SingleNews = props => {
  const { response, setResponse } = useContext(NewsContext);
  const { drawer, setDrawer, selectedNews, setSelectedNews } = props;
  const [imageLoaded, setImageLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const transformedNews = {
    Title: selectedNews.title,
    'Short description': selectedNews.short_description,
    Text: selectedNews.text
  };

  useEffect(() => {
    if (drawer) {
      setLoading(true);
    }
  }, [drawer]);

  useEffect(() => {
    if (
      response.requestFor === 'get single' &&
      response.requestInProgress === false &&
      response.id
    ) {
      setLoading(false);
    }
  }, [response]);

  return (
    <Drawer
      width={640}
      placement="right"
      closable={false}
      onClose={() => {
        setDrawer(false);
        setResponse({});
        setLoading(false);
        setTimeout(() => {
          setSelectedNews({});
        }, 300);
      }}
      visible={drawer}
    >
      <Spin spinning={loading}>
        {selectedNews.thumbnail ? (
          <StyledImageWrapper>
            <Spin spinning={!imageLoaded}>
              <StyledImage
                src={selectedNews.thumbnail}
                onLoad={() => setImageLoaded(true)}
              />
            </Spin>
          </StyledImageWrapper>
        ) : null}
      </Spin>

      <div>
        {Object.keys(transformedNews).map((keyName, i) => {
          return (
            <p key={i}>
              <Text>
                {keyName}:{' '}
                <Text type="secondary">{transformedNews[keyName]}</Text>
              </Text>
            </p>
          );
        })}
      </div>
      <h3>Gallery</h3>
      <Row gutter={16}>
        {(selectedNews.Medias || []).map(media => {
          return (
            <Col className="gutter-row" span={6} key={media.id}>
              <StyledImage src={media.path} />
            </Col>
          );
        })}
      </Row>
    </Drawer>
  );
};

export default SingleNews;
