import React, { useState, useEffect, createContext, useContext } from "react";
import { AwsClient } from "aws4fetch";

import { AuthContext } from "./AuthContext";
import { VenuesContext } from "./VenuesContext";
import { API_ENDPOINT } from "../consts/globals";

export const TrainingsContext = createContext();

const TrainingsContextProvider = (props) => {
  const { venues, getVenues } = useContext(VenuesContext);
  const { creds } = useContext(AuthContext);
  const [trainings, setTrainings] = useState([]);
  const [transformedTrainings, setTransformedTrainings] = useState([]);
  const [response, setResponse] = useState({});

  const abortController = new AbortController();
  const signal = abortController.signal;

  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken,
  });

  const getTrainings = async () => {
    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/events?filter-type=training`, {
        signal: signal,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setTrainings(responseJson.data);
    } catch (error) {
      console.log(error);
    }
  };

  const createTraining = async (training) => {
    setResponse({});

    let image64;

    if (training.image) {
      image64 = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(training.image[0].originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }

    try {
      const transformedTraining = {
        ...training,
        image: await image64,
        type: 'training'
      };

      const response = await aws.fetch(`${API_ENDPOINT}/system/events`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(transformedTraining),
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getTrainings();
    } catch (error) {
      console.log(error);
    }
  };

  const editTraining = async (training, id) => {
    setResponse({});

    let image64;

    if (training.image) {
      image64 = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(training.image[0].originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }

    try {
      const { image, ...transformedTraining} = training;

      if (training.image) {
        transformedTraining.image = await image64;
      }

      transformedTraining.type = 'training';

      const response = await aws.fetch(`${API_ENDPOINT}/system/events/${id}`, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(transformedTraining),
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getTrainings();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteTraining = async (id) => {
    setResponse({});

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/events/${id}`, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getTrainings();
    } catch (error) {
      console.log(error);
    }
  };

  const transformResults = (trainings) => {
    const transformedArray = trainings.map((training) => {
      const transformedTraining = {
        ...training,
        venue: venues.find((obj) => {
          return obj.id === training.venue_id;
        }),
      };

      return transformedTraining;
    });

    setTransformedTrainings(transformedArray);
  };

  useEffect(() => {
    getVenues();
    getTrainings();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (venues.length > 0) {
      transformResults(trainings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venues, trainings]);

  return (
    <TrainingsContext.Provider
      value={{
        venues,
        trainings,
        getTrainings,
        transformedTrainings,
        createTraining,
        editTraining,
        deleteTraining,
        response,
        setResponse,
      }}
    >
      {props.children}
    </TrainingsContext.Provider>
  );
};

export default TrainingsContextProvider;
