import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Upload,
  Button,
  Icon,
  DatePicker,
} from "antd";
import moment from "moment";

import { TrainingsContext } from "../../contexts/TrainingsContext";

const { Option } = Select;

const EditTrainingForm = (props) => {
  const { venues, editTraining, response, setResponse } = useContext(
    TrainingsContext
  );
  const { modalVisible, setModalVisible, modal, selectedTraining } = props;
  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);

  const { getFieldDecorator, getFieldError } = props.form;

  const titleError = getFieldError("title") || validationError.title;
  const descriptionError =
    getFieldError("description") || validationError.description;
  const durationError = getFieldError("duration") || validationError.duration;
  const totalSlotsError =
    getFieldError("total_slots") || validationError.total_slots;
  const priceError = getFieldError("price") || validationError.price;
  const trainerTitleError =
    getFieldError("trainer_title") || validationError.trainer_title;
  const trainerDescError =
    getFieldError("trainer_description") || validationError.trainer_description;
  const venueError = getFieldError("venue_id") || validationError.venue_id;
  const imageError = getFieldError("image") || validationError.image;
  const skillError =
    getFieldError("skill_level") || validationError.skill_level;
  const startsError = getFieldError("starts_at") || validationError.starts_at;

  const closeModal = () => {
    setValidationError({});
    setModalVisible(false);
    props.form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);
    props.form.validateFields((error, fieldsValue) => {
      if (error) {
        setLoading(false);
        return;
      }

      const { date, ...noDate } = fieldsValue;
      let values;

      if (fieldsValue.starts_at) {
        values = {
          ...noDate,
          starts_at: fieldsValue.starts_at.format("YYYY-MM-DD HH:mm:ss"),
        };
      } else {
        values = {
          ...noDate,
        };
      }

      editTraining(values, selectedTraining.id);
    });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  const handleChange = (e) => {
    const eventTarget = e.target;
    const currentValidation = { ...validationError };

    if (e.file) {
      currentValidation.image = undefined;
    } else {
      if (eventTarget) {
        const fieldName = eventTarget.name;
        currentValidation[fieldName] = undefined;
      } else {
        currentValidation.venue_id = undefined;
      }
    }

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleDate = () => {
    const currentValidation = { ...validationError };

    currentValidation.date = undefined;

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const normFile = (e) => {
    if (e.file && !(e.file.size / 1024 < 800)){
      const currentValidation = { ...validationError };
      currentValidation.image = 'Image must be smaller than 800kB!';
      setValidationError(currentValidation);
      return false;
    }

    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    if (response.errors) {
      setValidationError(response.errors);
    } else {
      setValidationError({});
    }

    if (response.id) {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === "edit") {
    return (
      <Modal
        title={`Edit ${selectedTraining.title}`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form layout="vertical" onSubmit={handleOk}>
          <Form.Item
            label="Title"
            validateStatus={titleError ? "error" : ""}
            help={titleError || ""}
          >
            {getFieldDecorator("title", {
              initialValue: selectedTraining.title,
              rules: [
                { required: true, message: "Please input an training title!" },
              ],
              onChange: (e) => handleChange(e),
            })(<Input name="title" placeholder="Title" />)}
          </Form.Item>
          <Form.Item
            label="Venue"
            validateStatus={venueError ? "error" : ""}
            help={venueError || ""}
          >
            {getFieldDecorator("venue_id", {
              initialValue: selectedTraining.venue_id,
              rules: [{ required: true, message: "Please select a venue!" }],
              onChange: (e) => handleChange(e),
            })(
              <Select
                name="venue_id"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Venue"
              >
                {venues.map((venue) => {
                  return (
                    <Option key={venue.id} value={venue.id}>
                      {venue.title}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label="Starts at"
            validateStatus={startsError ? "error" : ""}
            help={startsError || ""}
          >
            {getFieldDecorator("starts_at", {
              initialValue: selectedTraining.starts_at
                ? moment(selectedTraining.starts_at)
                : null,
              onChange: () => handleDate(),
            })(<DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />)}
          </Form.Item>
          <Form.Item
            label="Description"
            validateStatus={descriptionError ? "error" : ""}
            help={descriptionError || ""}
          >
            {getFieldDecorator("description", {
              initialValue: selectedTraining.description,
              rules: [
                {
                  required: true,
                  message: "Please input an training description!",
                },
              ],
              onChange: (e) => handleChange(e),
            })(<Input name="description" placeholder="Description" />)}
          </Form.Item>
          <Form.Item
            label="Duration"
            validateStatus={durationError ? "error" : ""}
            help={durationError || ""}
          >
            {getFieldDecorator("duration", {
              initialValue: selectedTraining.duration,
              rules: [
                { required: true, message: "Please input an training duration!" },
                {
                  pattern: /^\d*$/,
                  message: "Please input a number!",
                },
              ],
              onChange: (e) => handleChange(e),
            })(<Input name="duration" placeholder="Duration" />)}
          </Form.Item>
          <Form.Item
            label="Total slots"
            validateStatus={totalSlotsError ? "error" : ""}
            help={totalSlotsError || ""}
          >
            {getFieldDecorator("total_slots", {
              initialValue: selectedTraining.total_slots,
              rules: [
                { required: true, message: "Please input total slots!" },
                {
                  pattern: /^\d*$/,
                  message: "Please input a number!",
                },
              ],
              onChange: (e) => handleChange(e),
            })(<Input name="total_slots" placeholder="Total slots" />)}
          </Form.Item>
          <Form.Item
            label="Price €"
            validateStatus={priceError ? "error" : ""}
            help={priceError || ""}
          >
            {getFieldDecorator("price", {
              initialValue: selectedTraining.price,
              rules: [
                { required: true, message: "Please input a price!" },
                {
                  pattern: /^\d*$/,
                  message: "Please input a number!",
                },
              ],
              onChange: (e) => handleChange(e),
            })(<Input name="price" placeholder="Price €" />)}
          </Form.Item>
          <Form.Item
            label="Trainer title"
            validateStatus={trainerTitleError ? "error" : ""}
            help={trainerTitleError || ""}
          >
            {getFieldDecorator("trainer_title", {
              initialValue: selectedTraining.trainer_title,
              rules: [
                { required: true, message: "Please input a trainer title!" },
              ],
              onChange: (e) => handleChange(e),
            })(<Input name="trainer_title" placeholder="Trainer title" />)}
          </Form.Item>
          <Form.Item
            label="Trainer description"
            validateStatus={trainerDescError ? "error" : ""}
            help={trainerDescError || ""}
          >
            {getFieldDecorator("trainer_description", {
              initialValue: selectedTraining.trainer_description,
              rules: [
                {
                  required: true,
                  message: "Please input a trainer description!",
                },
              ],
              onChange: (e) => handleChange(e),
            })(
              <Input
                name="trainer_description"
                placeholder="Trainer description"
              />
            )}
          </Form.Item>
          <Form.Item
            label="Skill level"
            validateStatus={skillError ? "error" : ""}
            help={skillError || ""}
          >
            {getFieldDecorator("skill_level", {
              initialValue: selectedTraining.skill_level,
              rules: [
                { required: true, message: "Please input a skill level!" },
              ],
              onChange: (e) => handleChange(e),
            })(<Input name="skill_level" placeholder="Skill level" />)}
          </Form.Item>
          <Form.Item
            label="Image"
            validateStatus={imageError ? "error" : ""}
            help={imageError || ""}
          >
            {getFieldDecorator("image", {
              rules: [
                { required: true, message: "Please upload an training image!" },
              ],
              valuePropName: "fileList",
              getValueFromEvent: normFile,
              onChange: (e) => handleChange(e),
            })(
              <Upload name="image" beforeUpload={() => false}>
                <Button>
                  <Icon type="upload" /> Click to upload image
                </Button>
              </Upload>
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

const EditTraining = Form.create()(EditTrainingForm);

export default EditTraining;
