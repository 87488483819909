import React, { useContext, useEffect, useState, useRef } from 'react';
import { Modal, Form, Input, Upload, Icon, Spin } from 'antd';

import { NewsContext } from '../../contexts/NewsContext';

// const { Option } = Select;
const { TextArea } = Input;

const EditNewsForm = props => {
  const {
    editNews,
    response,
    setResponse,
    asyncForEach,
    gallery,
    setGallery,
    thumbnail,
    setThumbnail
  } = useContext(NewsContext);
  const {
    modalVisible,
    setModalVisible,
    modal,
    selectedNews,
    setSelectedNews
  } = props;
  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [deleteMedias, setDeleteMedias] = useState([]);
  const [descCharCount, setDescCharCount] = useState(0);
  const [shortDescCharCount, setShortDescCharCount] = useState(0);
  const descInput = useRef(null);
  const shortDescInput = useRef(null);

  const { getFieldDecorator, getFieldError } = props.form;

  const titleError = getFieldError('title') || validationError.title;
  const shortDescriptionError =
    getFieldError('short_description') || validationError.short_description;
  const textError = getFieldError('text') || validationError.text;
  const thumbnailError =
    getFieldError('thumbnail') || validationError.thumbnail;
  const galleryError = getFieldError('gallery') || validationError.gallery;

  // console.log(thumbnail)

  const closeModal = () => {
    setValidationError({});
    setModalVisible(false);
    setSelectedNews({});
    props.form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);

    props.form.validateFields(async (error, fieldsValue) => {
      if (error) {
        setLoading(false);
        return;
      }

      let add_medias = [];

      await (async () => {
        await asyncForEach(gallery, async file => {
          if (!file.existing) {
            add_medias.push(await getBase64(file.originFileObj));
          }
        });
      })();

      editNews(
        { ...fieldsValue, thumbnail, add_medias, delete_medias: deleteMedias },
        selectedNews.id
      );
      setThumbnail(undefined);
      setGallery([]);
      setDeleteMedias([]);
    });
  };

  const handleCancel = () => {
    setResponse({});
    setThumbnail(undefined);
    setGallery([]);
    setDeleteMedias([]);
    setLoading(false);
    closeModal();
  };

  const handleDescription = e => {
    setDescCharCount(e.target.value.length);

    const currentValidation = { ...validationError };

    if (e.file) {
      currentValidation.image = undefined;
    } else {
      const eventTarget = e.target;

      if (eventTarget) {
        const fieldName = eventTarget.name;
        currentValidation[fieldName] = undefined;
      }
    }

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleShortDescription = e => {
    setShortDescCharCount(e.target.value.length);

    const currentValidation = { ...validationError };

    if (e.file) {
      currentValidation.image = undefined;
    } else {
      const eventTarget = e.target;

      if (eventTarget) {
        const fieldName = eventTarget.name;
        currentValidation[fieldName] = undefined;
      }
    }

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleChange = async e => {
    const currentValidation = { ...validationError };

    if (e.file) {
      currentValidation.thumbnail = undefined;
    } else {
      const eventTarget = e.target;

      if (eventTarget) {
        const fieldName = eventTarget.name;
        currentValidation[fieldName] = undefined;
      }
    }

    if (validationError) {
      setValidationError(currentValidation);
    }

    if (e.file) {
      // Get this url from response in real world.
      setThumbnail(await getBase64(e.file));
    }
  };

  const normFile = e => {
    if (e.file && !(e.file.size / 1024 < 800)){
      const currentValidation = { ...validationError };
      currentValidation.image = 'Image must be smaller than 800kB!';
      setValidationError(currentValidation);
      return false;
    }

    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  const handleGalleryCancel = () => setPreviewVisible(false);

  const handleGalleryPreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  const handleGalleryChange = ({ fileList }) => {
    setGallery([...fileList]);
  };
  const handleGalleryRemove = file => {
    if (file.existing) {
      setDeleteMedias([...deleteMedias, file.uid]);
    }
  };

  useEffect(() => {
    if (modal === 'edit') {
      if (response.errors) {
        setValidationError(response.errors);
      } else {
        setValidationError({});
      }

      if (
        response.requestFor === 'get single' &&
        response.requestInProgress === true
      ) {
        setInitialLoading(true);
      } else if (
        response.requestFor === 'get single' &&
        response.requestInProgress === false
      ) {
        setInitialLoading(false);
      }

      if (
        response.requestFor === 'edit' &&
        response.requestInProgress === false &&
        response.id
      ) {
        closeModal();
      }

      if (
        response.requestFor === 'edit' &&
        response.requestInProgress === false
      ) {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    if (descInput.current) {
      setDescCharCount(descInput.current.textAreaRef.textLength);
    }

    if (shortDescInput.current) {
      setShortDescCharCount(shortDescInput.current.textAreaRef.textLength);
    }
  }, [selectedNews]);

  if (modal === 'edit') {
    return (
      <Modal
        title={`Edit news`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={{ maxHeight: '50vh', overflowY: 'scroll' }}
        confirmLoading={loading}
      >
        <Spin spinning={initialLoading}>
          <Form layout="vertical" onSubmit={handleOk}>
            <Form.Item
              label="Title"
              validateStatus={titleError ? 'error' : ''}
              help={titleError || ''}
            >
              {getFieldDecorator('title', {
                initialValue: selectedNews.title,
                rules: [
                  { required: true, message: 'Please input a news title!' }
                ],
                onChange: e => handleChange(e)
              })(<Input name="title" placeholder="Title" />)}
            </Form.Item>

            <Form.Item
              label={`Short description ${shortDescCharCount}/60`}
              validateStatus={shortDescriptionError ? 'error' : ''}
              help={shortDescriptionError || ''}
            >
              {getFieldDecorator('short_description', {
                initialValue: selectedNews.short_description,
                rules: [
                  {
                    required: true,
                    message: 'Please enter a short description!'
                  }
                ],
                onChange: e => handleShortDescription(e)
              })(
                <TextArea
                  ref={shortDescInput}
                  autoSize={true}
                  name="short_description"
                  placeholder="Short description"
                  maxLength={60}
                />
              )}
            </Form.Item>

            <Form.Item
              label={`Text ${descCharCount}/∞`}
              validateStatus={textError ? 'error' : ''}
              help={textError || ''}
            >
              {getFieldDecorator('text', {
                initialValue: selectedNews.text,
                rules: [
                  { required: true, message: 'Please input a news text!' }
                ],
                onChange: e => handleDescription(e)
              })(
                <TextArea
                  ref={descInput}
                  autoSize={true}
                  name="text"
                  placeholder="Text"
                />
              )}
            </Form.Item>

            <Form.Item
              validateStatus={thumbnailError ? 'error' : ''}
              help={thumbnailError || ''}
              label="Thumbnail"
            >
              {getFieldDecorator('thumbnail', {
                valuePropName: 'fileList',
                getValueFromEvent: normFile,
                onChange: e => handleChange(e)
              })(
                <Upload
                  name="thumbnail"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={() => false}
                >
                  {thumbnail ? (
                    <img
                      src={thumbnail}
                      alt="avatar"
                      style={{ width: '100%' }}
                    />
                  ) : (
                    <div>
                      <Icon type={loading ? 'loading' : 'plus'} />
                      <div className="ant-upload-text">Upload</div>
                    </div>
                  )}
                </Upload>
              )}
            </Form.Item>

            <Form.Item
              validateStatus={galleryError ? 'error' : ''}
              help={galleryError || ''}
              label="Gallery"
            >
              <div className="clearfix">
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture-card"
                  fileList={gallery}
                  onPreview={handleGalleryPreview}
                  onChange={handleGalleryChange}
                  onRemove={handleGalleryRemove}
                >
                  <div>
                    <Icon type="plus" />
                    <div className="ant-upload-text">Upload</div>
                  </div>
                </Upload>
                <Modal
                  visible={previewVisible}
                  footer={null}
                  onCancel={handleGalleryCancel}
                >
                  <img
                    alt="example"
                    style={{ width: '100%' }}
                    src={previewImage}
                  />
                </Modal>
              </div>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  } else {
    return null;
  }
};

const EditNews = Form.create()(EditNewsForm);

export default EditNews;
