import React, { useState, useEffect, createContext, useContext } from "react";
import { AwsClient } from "aws4fetch";

import { AuthContext } from "./AuthContext";
import { VenuesContext } from "./VenuesContext";
import { API_ENDPOINT } from "../consts/globals";

export const EventsContext = createContext();

const EventsContextProvider = (props) => {
  const { venues, getVenues } = useContext(VenuesContext);
  const { creds } = useContext(AuthContext);
  const [events, setEvents] = useState([]);
  const [transformedEvents, setTransformedEvents] = useState([]);
  const [response, setResponse] = useState({});

  const abortController = new AbortController();
  const signal = abortController.signal;

  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken,
  });

  const getEvents = async () => {
    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/events?filter-type=event`, {
        signal: signal,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setEvents(responseJson.data);
    } catch (error) {
      console.log(error);
    }
  };

  const createEvent = async (event) => {
    setResponse({});

    let image64;

    if (event.image) {
      image64 = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(event.image[0].originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }

    try {
      const transformedEvent = {
        ...event,
        image: await image64,
      };

      const response = await aws.fetch(`${API_ENDPOINT}/system/events`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(transformedEvent),
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getEvents();
    } catch (error) {
      console.log(error);
    }
  };

  const editEvent = async (event, id) => {
    setResponse({});

    let image64;

    if (event.image) {
      image64 = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(event.image[0].originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }

    try {
      const { image, ...transformedEvent } = event;

      if (event.image) {
        transformedEvent.image = await image64;
      }

      const response = await aws.fetch(`${API_ENDPOINT}/system/events/${id}`, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(transformedEvent),
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getEvents();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteEvent = async (id) => {
    setResponse({});

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/events/${id}`, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });

      const responseJson = await response.json();
      setResponse(responseJson);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getEvents();
    } catch (error) {
      console.log(error);
    }
  };

  const transformResults = (events) => {
    const transformedArray = events.map((event) => {
      const transformedEvent = {
        ...event,
        venue: venues.find((obj) => {
          return obj.id === event.venue_id;
        }),
      };

      return transformedEvent;
    });

    setTransformedEvents(transformedArray);
  };

  useEffect(() => {
    getVenues();
    getEvents();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (venues.length > 0) {
      transformResults(events);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venues, events]);

  return (
    <EventsContext.Provider
      value={{
        venues,
        events,
        getEvents,
        transformedEvents,
        createEvent,
        editEvent,
        deleteEvent,
        response,
        setResponse,
      }}
    >
      {props.children}
    </EventsContext.Provider>
  );
};

export default EventsContextProvider;
