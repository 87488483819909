import React, { useContext, useState, Fragment } from "react";
import styled from "styled-components";
import { Table, Icon, Button, Divider } from "antd";

import { EventsContext } from "../../contexts/EventsContext";
import CreateEvent from "./CreateEvent";
import EditEvent from "./EditEvent";
import DeleteEvent from "./DeleteEvent";

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const StyledIcon = styled.span`
  cursor: pointer;
`;

const EventsList = () => {
  const { transformedEvents } = useContext(EventsContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState("");
  const [selectedEvent, setSelectedEvent] = useState({});

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "ID",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Total slots",
      dataIndex: "total_slots",
      sorter: (a, b) => a.total_slots - b.total_slots,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Available slots",
      dataIndex: "available_slots",
      sorter: (a, b) => a.available_slots - b.available_slots,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Price €",
      dataIndex: "price",
      sorter: (a, b) => a.price - b.price,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      render: (event) => (
        <span>
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal("edit");
              setSelectedEvent(event);
            }}
          >
            <Icon type="edit" />
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal("delete");
              setSelectedEvent(event);
            }}
          >
            <Icon type="delete" />
          </StyledIcon>
        </span>
      ),
    },
  ];

  return (
    <Fragment>
      <StyledButtonWrapper>
        <StyledButton
          shape="circle"
          icon="plus"
          type="primary"
          onClick={() => {
            setModalVisible(true);
            setModal("create");
          }}
        ></StyledButton>
      </StyledButtonWrapper>
      <Table
        columns={columns}
        rowKey={(event) => event.id}
        dataSource={transformedEvents}
        pagination={{ pageSize: 10 }}
        scroll={{ x: "max-content" }}
      />
      <CreateEvent
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <EditEvent
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedEvent={selectedEvent}
      />
      <DeleteEvent
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedEvent={selectedEvent}
      />
    </Fragment>
  );
};

export default EventsList;
