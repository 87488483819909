import React, { useContext, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Table, Divider, Icon, Button, Input } from 'antd';
// import { Link } from 'react-router-dom';

import { UsersContext } from '../../contexts/UsersContext';
import CreateUser from './CreateUser';
import EditUser from './EditUser';
import DeleteUser from './DeleteUser';
import ChangeUserGroup from './ChangeUserGroup';

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const StyledIcon = styled.span`
  cursor: pointer;
`;

const UsersList = () => {
  const { transformedUsers, getUserGroups } = useContext(UsersContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [selectedUser, setSelectedUser] = useState({});
  // const [searchText, setSearchText] = useState('');
  // const [searchedColumn, setSearchedColumn] = useState('');
  let searchInput;

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    }
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    // setSearchText(selectedKeys[0]);
    // setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    // setSearchText('');
  };

  const columns = [
    {
      title: 'Contact',
      dataIndex: 'contact',
      sorter: (a, b) => a.contact.localeCompare(b.contact),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('contact')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('status')
    },
    {
      title: 'Action',
      render: user => (
        <span>
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('group');
              setSelectedUser(user);
              getUserGroups(user);
            }}
          >
            <Icon type="user" />
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('edit');
              setSelectedUser(user);
            }}
          >
            <Icon type="edit" />
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('delete');
              setSelectedUser(user);
            }}
          >
            <Icon type="delete" />
          </StyledIcon>
        </span>
      )
    }
  ];

  return (
    <Fragment>
      <StyledButtonWrapper>
        <StyledButton
          shape="circle"
          icon="plus"
          type="primary"
          onClick={() => {
            setModalVisible(true);
            setModal('create');
          }}
        ></StyledButton>
      </StyledButtonWrapper>
      <Table
        columns={columns}
        rowKey={user => user.id}
        dataSource={transformedUsers}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
      />
      <CreateUser
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <ChangeUserGroup
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedUser={selectedUser}
      />
      <EditUser
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedUser={selectedUser}
      />
      <DeleteUser
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedUser={selectedUser}
      />
    </Fragment>
  );
};

export default UsersList;
