import React from 'react';
import { Drawer, Typography } from 'antd';

const { Text } = Typography;

const SingleInvoice = props => {
  const { drawer, setDrawer, selectedInvoice, setSelectedInvoice } = props;

  const transformedInvoice = {
    Number: selectedInvoice.invoice_number,
    ID: selectedInvoice.id,
    'Issued at': selectedInvoice.issued_at,
    Status: selectedInvoice.status,
    'User ID': selectedInvoice.user_id,
    'User email': selectedInvoice.billing_email || '-',
    'User phone': selectedInvoice.billing_phone || '-',
    'Billing company': selectedInvoice.billing_company || '-',
    'Billing full name': selectedInvoice.billing_first_name || '-',
    // 'Billing last name': selectedInvoice.billing_last_name || '-',
    'Billing address': selectedInvoice.billing_address || '-',
    'Billing city': selectedInvoice.billing_city || '-',
    'Billing ZIP code': selectedInvoice.billing_zip_code || '-',
    'Billing country': selectedInvoice.billing_country || '-'
  };

  return (
    <Drawer
      width={640}
      placement="right"
      closable={false}
      onClose={() => {
        setDrawer(false);
        setTimeout(() => {
          setSelectedInvoice({});
        }, 300);
      }}
      visible={drawer}
    >
      <div>
        {Object.keys(transformedInvoice).map((keyName, i) => {
          return (
            <p key={i}>
              <Text>
                {keyName}:{' '}
                <Text type="secondary">{transformedInvoice[keyName]}</Text>
              </Text>
            </p>
          );
        })}
      </div>
    </Drawer>
  );
};

export default SingleInvoice;
