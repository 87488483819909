import React, { useContext, useState, useEffect } from "react";
import { Modal } from "antd";
import styled from "styled-components";

import { VenuesContext } from "../../contexts/VenuesContext";

const StyledBold = styled.span`
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 22px;
`;

const DeleteVenue = (props) => {
  const { deleteVenue, response, setResponse } = useContext(VenuesContext);
  const { modalVisible, setModalVisible, modal, selectedVenue } = props;
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleOk = () => {
    setLoading(true);
    deleteVenue(selectedVenue.id);
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    if (Object.entries(response).length !== 0 && !response.errors) {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === "delete") {
    return (
      <Modal
        title={`Delete ${selectedVenue.title}`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <p>
          This operation will permanently delete{" "}
          <StyledBold>{selectedVenue.title.toUpperCase()}</StyledBold>!
        </p>
        <p>Are you sure you want to continue?</p>
      </Modal>
    );
  } else {
    return null;
  }
};

export default DeleteVenue;
