import React, { useContext, useEffect, useState } from "react";
import { Modal, Form, Input } from "antd";

import { VenuesContext } from "../../contexts/VenuesContext";

// function hasErrors(fieldsError) {
//   return Object.keys(fieldsError).some(field => fieldsError[field]);
// }

const EditVenueForm = (props) => {
  const { editVenue, response, setResponse } = useContext(VenuesContext);
  const { modalVisible, setModalVisible, modal, selectedVenue } = props;
  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);

  const { getFieldDecorator, getFieldError } = props.form;

  const titleError = getFieldError("title") || validationError.title;
  const configurationError =
    getFieldError("configuration") || validationError.configuration;
  const descriptionError =
    getFieldError("description") || validationError.description;
  const contactDetailsError =
    getFieldError("contact_details") || validationError.contact_details;
  const addressLineError =
    getFieldError("address_line_1") || validationError.address_line_1;
  const cityError = getFieldError("city") || validationError.city;
  const countryError = getFieldError("country") || validationError.country;
  const zipCodeError = getFieldError("zip") || validationError.zip;
  const latError = getFieldError("lat") || validationError.lat;
  const lngError = getFieldError("lng") || validationError.lng;
  const phoneError = getFieldError("phone") || validationError.phone;
  const websiteError = getFieldError("website") || validationError.website;
  const facebookError =
    getFieldError("facebook_link") || validationError.facebook_link;
  const instagramError =
    getFieldError("instagram_link") || validationError.instagram_link;
  const twitterError =
    getFieldError("twitter_link") || validationError.twitter_link;
  const linkedInError =
    getFieldError("linked_in_link") || validationError.linked_in_link;
  const computedConfigurationError =
    getFieldError("computer_configuration") ||
    validationError.computer_configuration;

  const closeModal = () => {
    setValidationError({});
    setModalVisible(false);
    props.form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);

    props.form.validateFields((error, values) => {
      if (error) {
        setLoading(false);
        return;
      }

      editVenue(values, selectedVenue.id);
    });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  const handleChange = (e) => {
    const currentValidation = { ...validationError };

    if (e.file) {
      currentValidation.image = undefined;
    } else {
      const eventTarget = e.target;

      if (eventTarget) {
        const fieldName = eventTarget.name;
        currentValidation[fieldName] = undefined;
      }
    }

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  // const normFile = e => {
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e && e.fileList;
  // };

  useEffect(() => {
    if (response.errors) {
      setValidationError(response.errors);
    } else {
      setValidationError({});
    }

    if (response.id) {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === "edit") {
    return (
      <Modal
        title={`Edit ${selectedVenue.title}`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form layout="vertical" onSubmit={handleOk}>
          <Form.Item
            label="Title"
            validateStatus={titleError ? "error" : ""}
            help={titleError || ""}
          >
            {getFieldDecorator("title", {
              initialValue: selectedVenue.title,
              rules: [
                { required: true, message: "Please input a venue title!" },
              ],
              onChange: (e) => handleChange(e),
            })(<Input name="title" placeholder="Title" />)}
          </Form.Item>
          <Form.Item
            label="Configuration"
            validateStatus={configurationError ? "error" : ""}
            help={configurationError || ""}
          >
            {getFieldDecorator("configuration", {
              initialValue: selectedVenue.configuration,
              rules: [
                {
                  required: true,
                  message: "Please input a venue configuration!",
                },
              ],
              onChange: (e) => handleChange(e),
            })(<Input name="configuration" placeholder="Configuration" />)}
          </Form.Item>
          <Form.Item
            label="Description"
            validateStatus={descriptionError ? "error" : ""}
            help={descriptionError || ""}
          >
            {getFieldDecorator("description", {
              initialValue: selectedVenue.description,
              rules: [
                {
                  required: true,
                  message: "Please input a venue description!",
                },
              ],
              onChange: (e) => handleChange(e),
            })(<Input name="description" placeholder="Description" />)}
          </Form.Item>
          <Form.Item
            label="Contact details"
            validateStatus={contactDetailsError ? "error" : ""}
            help={contactDetailsError || ""}
          >
            {getFieldDecorator("contact_details", {
              initialValue: selectedVenue.contact_details,
              onChange: (e) => handleChange(e),
            })(<Input name="contact_details" placeholder="Contact details" />)}
          </Form.Item>
          <Form.Item
            label="Address line"
            validateStatus={addressLineError ? "error" : ""}
            help={addressLineError || ""}
          >
            {getFieldDecorator("address_line_1", {
              initialValue: selectedVenue.address_line_1,
              onChange: (e) => handleChange(e),
            })(<Input name="address_line_1" placeholder="Address line" />)}
          </Form.Item>
          <Form.Item
            label="City"
            validateStatus={cityError ? "error" : ""}
            help={cityError || ""}
          >
            {getFieldDecorator("city", {
              initialValue: selectedVenue.city,
              onChange: (e) => handleChange(e),
            })(<Input name="city" placeholder="City" />)}
          </Form.Item>
          <Form.Item
            label="Country"
            validateStatus={countryError ? "error" : ""}
            help={countryError || ""}
          >
            {getFieldDecorator("country", {
              initialValue: selectedVenue.country,
              onChange: (e) => handleChange(e),
            })(<Input name="country" placeholder="Country" />)}
          </Form.Item>
          <Form.Item
            label="ZIP code"
            validateStatus={zipCodeError ? "error" : ""}
            help={zipCodeError || ""}
          >
            {getFieldDecorator("zip", {
              initialValue: selectedVenue.zip,
              rules: [
                {
                  pattern: /^\d*$/,
                  message: "Please input a number!",
                },
                {
                  min: 4,
                  message: "Zip code must be between 4 and 9 characters!",
                },
                {
                  max: 9,
                  message: "Zip code must be between 4 and 9 characters!",
                },
              ],
              onChange: (e) => handleChange(e),
            })(<Input name="zip" placeholder="Zip code" maxLength={9} />)}
          </Form.Item>
          <Form.Item
            label="Latitude"
            validateStatus={latError ? "error" : ""}
            help={latError || ""}
          >
            {getFieldDecorator("lat", {
              initialValue: selectedVenue.lat,
              rules: [
                {
                  pattern: /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/,
                  message: "Wrong format!",
                },
              ],
              onChange: (e) => handleChange(e),
            })(<Input name="lat" placeholder="Latitude" />)}
          </Form.Item>
          <Form.Item
            label="Longitude"
            validateStatus={lngError ? "error" : ""}
            help={lngError || ""}
          >
            {getFieldDecorator("lng", {
              initialValue: selectedVenue.lng,
              rules: [
                {
                  pattern: /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/,
                  message: "Wrong format!",
                },
              ],
              onChange: (e) => handleChange(e),
            })(<Input name="lat" placeholder="Longitude" />)}
          </Form.Item>
          <Form.Item
            label="Phone"
            validateStatus={phoneError ? "error" : ""}
            help={phoneError || ""}
          >
            {getFieldDecorator("phone", {
              initialValue: selectedVenue.phone,
              rules: [
                {
                  pattern: /^[+]?[0-9]{4,16}$/,
                  message: "The input is not a valid phone number!",
                },
              ],
              onChange: (e) => handleChange(e),
            })(<Input name="phone" placeholder="Phone" />)}
          </Form.Item>
          <Form.Item
            label="Website"
            validateStatus={websiteError ? "error" : ""}
            help={websiteError || ""}
          >
            {getFieldDecorator("website", {
              initialValue: selectedVenue.website,
              rules: [
                {
                  pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:http:\/\/.|[-;:&=+$,\w]+@)(?:https:\/\/.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/,
                  message: "The input is not a valid URL!",
                },
              ],
              onChange: (e) => handleChange(e),
            })(<Input name="website" placeholder="Website" />)}
          </Form.Item>
          <Form.Item
            label="Facebook link"
            validateStatus={facebookError ? "error" : ""}
            help={facebookError || ""}
          >
            {getFieldDecorator("facebook_link", {
              initialValue: selectedVenue.facebook_link,
              rules: [
                {
                  pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:http:\/\/.|[-;:&=+$,\w]+@)(?:https:\/\/.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/,
                  message: "The input is not a valid URL!",
                },
              ],
              onChange: (e) => handleChange(e),
            })(<Input name="facebook_link" placeholder="Facebook link" />)}
          </Form.Item>
          <Form.Item
            label="Instagram link"
            validateStatus={instagramError ? "error" : ""}
            help={instagramError || ""}
          >
            {getFieldDecorator("instagram_link", {
              initialValue: selectedVenue.instagram_link,
              rules: [
                {
                  pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:http:\/\/.|[-;:&=+$,\w]+@)(?:https:\/\/.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/,
                  message: "The input is not a valid URL!",
                },
              ],
              onChange: (e) => handleChange(e),
            })(<Input name="instagram_link" placeholder="Instagram link" />)}
          </Form.Item>
          <Form.Item
            label="Twitter link"
            validateStatus={twitterError ? "error" : ""}
            help={twitterError || ""}
          >
            {getFieldDecorator("twitter_link", {
              initialValue: selectedVenue.twitter_link,
              rules: [
                {
                  pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:http:\/\/.|[-;:&=+$,\w]+@)(?:https:\/\/.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/,
                  message: "The input is not a valid URL!",
                },
              ],
              onChange: (e) => handleChange(e),
            })(<Input name="twitter_link" placeholder="Twitter link" />)}
          </Form.Item>
          <Form.Item
            label="LinkedIn link"
            validateStatus={linkedInError ? "error" : ""}
            help={linkedInError || ""}
          >
            {getFieldDecorator("linked_in_link", {
              initialValue: selectedVenue.linked_in_link,
              rules: [
                {
                  pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:http:\/\/.|[-;:&=+$,\w]+@)(?:https:\/\/.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/,
                  message: "The input is not a valid URL!",
                },
              ],
              onChange: (e) => handleChange(e),
            })(<Input name="linked_in_link" placeholder="LinkedIn link" />)}
          </Form.Item>
          <Form.Item
            label="Computer configuration"
            validateStatus={computedConfigurationError ? "error" : ""}
            help={computedConfigurationError || ""}
          >
            {getFieldDecorator("computer_configuration", {
              initialValue: selectedVenue.computer_configuration,
              onChange: (e) => handleChange(e),
            })(
              <Input
                name="computer_configuration"
                placeholder="Computer configuration"
              />
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

const EditVenue = Form.create()(EditVenueForm);

export default EditVenue;
