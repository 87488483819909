import React, { useContext } from "react";
import { Layout } from "antd";
import styled from "styled-components";
import { Switch, Route } from "react-router-dom";

import { APP_NAME } from "../consts/globals";

import { AdminContext } from "../contexts/AdminContext";
import Sidebar from "./shared/Sidebar";
import Header from "./shared/Header";
import Footer from "./shared/Footer";
import Welcome from "./Welcome";
import UsersContextProvider from "../contexts/UsersContext";
import UsersList from "../components/users/UsersList";
import NewsContextProvider from "../contexts/NewsContext";
import NewsList from "../components/news/NewsList";
import VenuesContextProvider from "../contexts/VenuesContext";
import VenuesList from "../components/venues/VenuesList";
import EventsContextProvider from "../contexts/EventsContext";
import EventsList from "../components/events/EventsList";
import TrainingsContextProvider from "../contexts/TrainingsContext";
import TrainingList from "../components/trainings/TrainingList";
import InvoicesContextProvider from "../contexts/InvoicesContext";
import OffersList from "./offers/OffersList";
import InvoicesList from "./invoices/InvoicesList";
import LegalContextProvider from "../contexts/LegalContext";
import LegalList from "./legal/LegalList";
import NotFound from "./NotFound";

const { Content } = Layout;

const StyledLayout = styled(Layout)`
  min-height: 100vh;
`;

const StyledContent = styled(Content)`
  margin: 1.5rem;
`;

const StyledWrapper = styled.div`
  padding: 1.5rem;
  background: #fff;
  position: relative;
`;

// const StyledAnalyticsWrapper = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: #f0f2f5;
//   z-index: 1;
// `;

const Home = () => {
  const { userRole } = useContext(AdminContext);

  return (
    <div>
      <StyledLayout>
        <Sidebar />
        <Layout>
          <Header />
          <StyledContent>
            <StyledWrapper>
              {(() => {
                switch (userRole) {
                  case `${APP_NAME}-admin`:
                    return (
                      <Switch>
                        <Route path="/" exact>
                          <Welcome />
                        </Route>
                        <Route path="/users">
                          <UsersContextProvider>
                            <UsersList />
                          </UsersContextProvider>
                        </Route>
                        <Route path="/news">
                          <NewsContextProvider>
                            <NewsList />
                          </NewsContextProvider>
                        </Route>
                        <Route path="/venues">
                          <VenuesContextProvider>
                            <VenuesList />
                          </VenuesContextProvider>
                        </Route>
                        <Route path="/events">
                          <VenuesContextProvider>
                            <EventsContextProvider>
                              <EventsList />
                            </EventsContextProvider>
                          </VenuesContextProvider>
                        </Route>
                        <Route path="/trainings">
                          <VenuesContextProvider>
                            <TrainingsContextProvider>
                              <TrainingList />
                            </TrainingsContextProvider>
                          </VenuesContextProvider>
                        </Route>
                        <Route path="/offers">
                          <InvoicesContextProvider>
                            <OffersList />
                          </InvoicesContextProvider>
                        </Route>
                        <Route path="/invoices">
                          <InvoicesContextProvider>
                            <InvoicesList />
                          </InvoicesContextProvider>
                        </Route>
                        <Route path="/legal">
                          <LegalContextProvider>
                            <LegalList />
                          </LegalContextProvider>
                        </Route>
                        <Route component={NotFound} />
                      </Switch>
                    );

                  case `${APP_NAME}-editor`:
                    return (
                      <Switch>
                        <Route path="/" exact>
                          <Welcome />
                        </Route>
                        <Route component={NotFound} />
                      </Switch>
                    );

                  default:
                    return (
                      <Switch>
                        <Route path="/" exact>
                          <Welcome />
                        </Route>
                        <Route component={NotFound} />
                      </Switch>
                    );
                }
              })()}
            </StyledWrapper>
          </StyledContent>
          <Footer />
        </Layout>
      </StyledLayout>
    </div>
  );
};

export default Home;
