import React, { useContext, useState, Fragment } from "react";
import styled from "styled-components";
import { Table, Icon, Button, Divider } from "antd";

import { VenuesContext } from "../../contexts/VenuesContext";
import CreateVenue from "./CreateVenue";
import EditVenue from "./EditVenue";
import DeleteVenue from "./DeleteVenue";

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const StyledIcon = styled.span`
  cursor: pointer;
`;

const CountriesList = () => {
  const { venues } = useContext(VenuesContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState("");
  const [selectedVenue, setSelectedVenue] = useState({});

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "ID",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Configuration",
      dataIndex: "configuration",
    },
    {
      title: "Action",
      render: (venue) => (
        <span>
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal("edit");
              setSelectedVenue(venue);
            }}
          >
            <Icon type="edit" />
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setSelectedVenue(venue);
              setModal("delete");
              setModalVisible(true);
            }}
          >
            <Icon type="delete" />
          </StyledIcon>
        </span>
      ),
    },
  ];

  return (
    <Fragment>
      <StyledButtonWrapper>
        <StyledButton
          shape="circle"
          icon="plus"
          type="primary"
          onClick={() => {
            setModalVisible(true);
            setModal("create");
          }}
        ></StyledButton>
      </StyledButtonWrapper>
      <Table
        columns={columns}
        rowKey={(venue) => venue.id}
        dataSource={venues}
        pagination={{ pageSize: 10 }}
        scroll={{ x: "max-content" }}
      />
      <CreateVenue
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <EditVenue
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedVenue={selectedVenue}
      />
      <DeleteVenue
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedVenue={selectedVenue}
      />
    </Fragment>
  );
};

export default CountriesList;
