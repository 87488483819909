import React, { useContext } from "react";
import { Layout, Menu, Icon } from "antd";
import styled from "styled-components";
import logo from "../../img/home-united-logo-white.png";
import { Link, withRouter } from "react-router-dom";

import { APP_NAME } from "../../consts/globals";

import { AdminContext } from "../../contexts/AdminContext";

const StyledSider = styled(Layout.Sider)`
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
`;

const StyledLogo = styled.div`
  padding: 1.5rem 1.5rem 2.5rem 1.5rem;

  img {
    height: 40px;
  }
`;

const Sidebar = (props) => {
  const { userRole } = useContext(AdminContext);
  const { location } = props;

  return (
    <StyledSider width="256">
      <StyledLogo>
        <Link to="/">
          <img src={logo} alt="home united logo" />
        </Link>
      </StyledLogo>

      {(() => {
        switch (userRole) {
          case `${APP_NAME}-admin`:
            return (
              <Menu
                theme="dark"
                selectedKeys={[location.pathname]}
                mode="inline"
              >
                <Menu.Item key="/users">
                  <Link to="/users">
                    <Icon type="team" />
                    <span>Users</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/news">
                  <Link to="/news">
                    <Icon type="file-text" />
                    <span>News</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/venues">
                  <Link to="/venues">
                    <Icon type="home" />
                    <span>Venues</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/events">
                  <Link to="/events">
                    <Icon type="global" />
                    <span>Events</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/trainings">
                  <Link to="/trainings">
                    <Icon type="global" />
                    <span>Trainings</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/offers">
                  <Link to="/offers">
                    <Icon type="calculator" />
                    <span>Offers</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/invoices">
                  <Link to="/invoices">
                    <Icon type="euro" />
                    <span>Invoices</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/legal">
                  <Link to="/legal">
                    <Icon type="file-done" />
                    <span>Legal</span>
                  </Link>
                </Menu.Item>
              </Menu>
            );

          case `${APP_NAME}-editor`:
            return (
              <Menu
                theme="dark"
                selectedKeys={[location.pathname]}
                mode="inline"
              ></Menu>
            );

          default:
            return (
              <Menu
                theme="dark"
                selectedKeys={[location.pathname]}
                mode="inline"
              ></Menu>
            );
        }
      })()}
    </StyledSider>
  );
};

export default withRouter(Sidebar);
